const encodeForUrl = require('./encodeForUrl')
const offerSlugBuy = require('./offerSlugBuy')
const resolveLinkBase = require('./offerResolveLinkBase')

const offerLinkBuy = (offer, encode = true) => {
    const linkBase = resolveLinkBase(offer, true)

    if (!linkBase) return '/'

    const tracking = offer.tracking || {}

    const id = offer._shortid
    const lang = tracking.lang && tracking.lang.toLowerCase() || 'pl'
    const slug = encodeForUrl(offerSlugBuy(offer) || 'bez-tytulu', encode)

    return [linkBase, 'oferta', slug, id, lang].join('/')
}



module.exports = offerLinkBuy